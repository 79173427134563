// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-final-tsx": () => import("./../src/templates/final.tsx" /* webpackChunkName: "component---src-templates-final-tsx" */),
  "component---src-templates-foreword-from-adobe-tsx": () => import("./../src/templates/foreword-from-adobe.tsx" /* webpackChunkName: "component---src-templates-foreword-from-adobe-tsx" */),
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-note-from-our-authors-tsx": () => import("./../src/templates/note-from-our-authors.tsx" /* webpackChunkName: "component---src-templates-note-from-our-authors-tsx" */),
  "component---src-templates-question-tsx": () => import("./../src/templates/question.tsx" /* webpackChunkName: "component---src-templates-question-tsx" */),
  "component---src-templates-result-tsx": () => import("./../src/templates/result.tsx" /* webpackChunkName: "component---src-templates-result-tsx" */)
}

