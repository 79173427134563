export const isTouch = typeof document !== `undefined` && ('ontouchstart' in document.documentElement);
declare global {
    interface Document {
        documentMode?: any;
    }
}
export const isMobile = typeof navigator !== 'undefined' && (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent));
export const isLinkedinApp = typeof navigator !== 'undefined' && (/LinkedInApp/i.test(navigator.userAgent));
export const isWeChatApp = typeof navigator !== 'undefined' && (/WeChat/i.test(navigator.userAgent));
export const isIpad = typeof navigator !== 'undefined' && (/iPad/i.test(navigator.userAgent));
export const isAndroid = typeof navigator !== 'undefined' && (/Android/i.test(navigator.userAgent));
export const isIphone = typeof navigator !== 'undefined' && (/iPhone|iPod/i.test(navigator.userAgent));
export const isFirefox = typeof navigator !== 'undefined' && (/Firefox|firefox/i.test(navigator.userAgent));
export const isIE11 = typeof window !== 'undefined' && !!window.MSInputMethodContext && !!document.documentMode;

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
export const shuffle = (array: any[]) => {
    for (let i:number = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
  
      // swap elements array[i] and array[j]
      // we use "destructuring assignment" syntax to achieve that
      // you'll find more details about that syntax in later chapters
      // same can be written as:
      // let t = array[i]; array[i] = array[j]; array[j] = t
      [array[i], array[j]] = [array[j], array[i]];
    }
}

export const truncateHTML = (elem: HTMLElement, characters: number) => {
    return elem.innerText.slice(0,characters);
}
export const truncateText = (text: string, characters: number) => {
    return text.slice(0,characters);
}

export const browserPopup = (url: string, channel: string) => {

    const w = 360;
    const h = 500;
    const title = '';

    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop

    var trackingChannel = 'Share to ' + channel;
    // gtag('event', trackingChannel, {
    //   'event_category': 'Page Shares',
    //   'event_label': url
    // });

      const newWindow = window.open(url, title, 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);
      if (window.focus) newWindow.focus();
    
    return false;

}