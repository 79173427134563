import React, { useReducer, useEffect, createContext } from "react"
import { loadState, saveState } from '../utils/localStorage'
import { AnswerType, QuestionOptionType } from '../types/question-types';
import { shuffle } from '../utils/utilities';

const initialState:StateI = {
  started: false,
  completed: false,
  eventsTriggered: false,
  currentQuestion: 1,
  totalQuestions: 15,
  answers: null,
  questions: [],
  questionsSort: []
}
interface StateI {
  started: boolean
  completed: boolean
  eventsTriggered: boolean
  totalQuestions: number,
  currentQuestion: number,
  answers: AnswerType,
  questions: any
  questionsSort: any
}

type ActionType = {
  type: 'UPDATE_CURRENT_QUESTION' | 'START_TEST' | 'RESTART_TEST' | 'SAVE_ANSWER' | 'SHUFFLE_OPTIONS' | 'COMPLETED' | 'EVENTS_TRIGGERED'
  currentQuestion: number
  questions?: any
  questionsSort?: any
  value: QuestionOptionType
}
export const GlobalStateContext = createContext(initialState)
export const GlobalDispatchContext = createContext<React.Dispatch<any>>(() => null)

const reducer = (state: StateI, action:ActionType) => {
  switch (action.type) {
    case "UPDATE_CURRENT_QUESTION": {
      return {
        ...state,
        currentQuestion: action.currentQuestion,
      }
    }
    case "START_TEST": {
      return {
        ...state,
        questions: action.questions,
        started: true,
      }
    }
    case "COMPLETED": {
      return {
        ...state,
        completed: true,
      }
    }
    case "EVENTS_TRIGGERED": {
      return {
        ...state,
        eventsTriggered: true,
      }
    }
    case "RESTART_TEST": {
      return {
        ...initialState,
        questions: action.questions
      }
    }
    case "SAVE_ANSWER": {
      const newAnswers:AnswerType = {...state.answers};
      newAnswers[`q${action.currentQuestion}`] = action.value;      
      return {
        ...state,
        answers: newAnswers
      }
    }
    case "SHUFFLE_OPTIONS": {
        const shuffledQuestions = state.questions.map((question:any) => {
            const newQuestion = { ...question };
            shuffle(newQuestion.options);
            return newQuestion;
        });
        return {
          ...state,
          questionsSort: shuffledQuestions
        }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }:any) => {
  const localState = loadState();
  const [state, dispatch] = useReducer(reducer, localState || initialState)

  useEffect(() => {
    saveState(state);
    return () => {
      
    }
  }, [state])
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider