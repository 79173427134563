module.exports = [{
      plugin: require('../plugins/adobe-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"304889680643399"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#D6D5C5","theme_color":"#D6D5C5","display":"minimal-ui","icon":"static/favicons/favicon.png","icons":[{"src":"static/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6d6fca138930382c6ba9fbec763f645f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-170476379-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
